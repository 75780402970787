import React from 'react';
import styled, { css } from 'styled-components';
import { AspectRatio, VideoPlayer } from '../VideoPlayer';

enum VideoModeEnum {
  AutoplayLoopMuted = 'AUTOPLAY_LOOP_MUTED',
  ClickplayNoloopSound = 'CLICKPLAY_NOLOOP_SOUND'
}

type VideoMode = VideoModeEnum.AutoplayLoopMuted | VideoModeEnum.ClickplayNoloopSound;

interface VideoProps {
  url: string;
  mode: VideoMode;
  thumbnailImage?: { url: string, alt: string };
  playButtonImage?: { url: string, alt: string };
  aspectRatio?: AspectRatio;
  onEnded?: () => void;
}

export const Video = React.forwardRef(
  (
    {
      url,
      mode,
      thumbnailImage,
      playButtonImage,
      aspectRatio
    }: VideoProps
  ) => {
    const [isPlaying, setIsPlaying] = React.useState(false);

    const reactPlayer = React.useRef<any>();

    const handleClickOpen = () => {
      reactPlayer.current.getInternalPlayer().play();
      setIsPlaying(true);
    };

    const onVideoEnded = () => {
      setIsPlaying(false);
    };

    return (
      <StyledVideoPlayerWrapper>
          <VideoPlayer
            ref={reactPlayer}
            url={url}
            isPlaying={mode === VideoModeEnum.AutoplayLoopMuted}
            isLooping={mode === VideoModeEnum.AutoplayLoopMuted}
            isMuted={mode === VideoModeEnum.AutoplayLoopMuted}
            showingControls={mode === VideoModeEnum.ClickplayNoloopSound}
            aspectRatio={aspectRatio}
            onEnded={onVideoEnded}
          />

          {mode === VideoModeEnum.ClickplayNoloopSound && (
            <StyledThumbnail onClick={handleClickOpen} isVisible={!isPlaying}>
              <StyledBackground>
                <img src={thumbnailImage?.url} alt={thumbnailImage?.alt} />
              </StyledBackground>

              <StyledForeground>
                <StyledPlayButton>
                  <img src={playButtonImage?.url} alt={playButtonImage?.alt} />
                </StyledPlayButton>
              </StyledForeground>
            </StyledThumbnail>
          )}
      </StyledVideoPlayerWrapper>
    )
  }
);

Video.defaultProps = {
  thumbnailImage: undefined,
  playButtonImage: undefined,
  aspectRatio: '16/9',
  onEnded: () => {},
};

const StyledVideoPlayerWrapper = styled.div`
  position: relative;
`;

const StyledThumbnail = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
`;

const StyledBackground = styled.div`
  width: 100%;
  flex: none;
  display: flex;

  img {
    align-self: flex-start;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & > div {
    align-self: flex-start;
    display: block;
    width: 100%;
  }
`;

const StyledForeground = styled.div`
  width: 100%;
  flex: none;
  margin-left: -100%;
  display: flex;

  & > * {
    flex: 1 0 0;
    padding: 2rem;

    @media ${({ theme }) => theme.screen.md.down} {
      padding: 1rem;
    }
  }
`;

const StyledPlayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 7rem;

    @media ${({ theme }) => theme.screen.md.down} {
      width: 5rem;
    }
  }

  & > div {
    width: 7rem;

    @media ${({ theme }) => theme.screen.md.down} {
      width: 5rem;
    }
  }
`;
