import React from 'react';
import { RichText as PrismicRichText, Elements } from 'prismic-reactjs';
import { H2, H3, P, UL, OL, LI } from '../page-elements';

const htmlSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.paragraph:
      return <P key={key}>{children}</P>;
    case Elements.heading2:
      return <H2 key={key}>{element.text}</H2>;
    case Elements.heading3:
      return <H3 key={key}>{element.text}</H3>;
    case Elements.list:
      return <UL key={key}>{children}</UL>;
    case Elements.oList:
      return <OL key={key}>{children}</OL>;
    case Elements.listItem:
    case Elements.oListItem:
      return <LI key={key}>{children}</LI>;

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

interface RichTextProps {
  raw: any;
}
export const RichText: React.FC<RichTextProps> = ({ raw }) => (
  <PrismicRichText render={raw} htmlSerializer={htmlSerializer} />
);
