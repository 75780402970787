import GatsbyImage from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { RichText } from '../RichText';
import { Video } from '../Video';

interface SliceProps {
  type: string;
  data: any;
}
export const Slice = ({ type, data }: SliceProps) => {
  const slice = SliceTypeRenderer[type];

  if (!slice) {
    return null;
  }

  return slice(data);
};

const SliceTypeRenderer = {
  section: sliceData => (
    <StyledSection>
      <RichText raw={sliceData.primary.text.raw} />
      {sliceData.primary.image.localFile && (
        <StyledImageWrapper>
          <GatsbyImage
            fluid={sliceData.primary.image.localFile.childImageSharp?.fluid}
          />
        </StyledImageWrapper>
      )}
    </StyledSection>
  ),
  video_section: sliceData => (
    <StyledVideoSection>
      <RichText raw={sliceData.primary.text.raw} />
      <StyledVideoWrapper>
        <Video
          url={sliceData.primary.video_vimeo_url}
          mode={sliceData.primary.play_option}
          aspectRatio={sliceData.primary.video_aspect_ratio}
          thumbnailImage={{
            url: sliceData.primary.video_thumbnail_image.url,
            alt: sliceData.primary.video_thumbnail_image_alt
          }}
          playButtonImage={{
            url: sliceData.primary.video_play_button_image.url,
            alt: sliceData.primary.video_play_button_image_alt
          }}
        />
      </StyledVideoWrapper>
    </StyledVideoSection>
  ),
  divider: () => <StyledDivider />
};

const StyledSection = styled.div`
  margin-bottom: 48px;
`;

const StyledImageWrapper = styled.div`
  margin-top: 24px;
`;

const StyledVideoSection = styled.div`
  margin-bottom: 48px;
`;

const StyledVideoWrapper = styled.div`
  margin-top: 24px;
`;

const StyledDivider = styled.hr`
  border: 0;
  border-top: 0.5px solid;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 48px;
`
