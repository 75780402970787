import React from 'react';
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import CircularProgress from '@material-ui/core/CircularProgress';

export type AspectRatio = '1/1' | '16/9'

interface VideoProps {
  url: string;
  isPlaying: boolean;
  isLooping: boolean;
  isMuted: boolean;
  showingControls: boolean;
  aspectRatio?: AspectRatio;
  onEnded?: () => void;
}
export const VideoPlayer = React.forwardRef(
  (
    {
      url,
      isPlaying,
      isLooping,
      isMuted,
      showingControls,
      aspectRatio,
      onEnded,
    }: VideoProps,
    ref
  ) => (
    <StyledVideoWrapper>
      <StyledPlayerWrapper aspectRatio={aspectRatio}>
        <StyledSpinner>
          <CircularProgress />
        </StyledSpinner>
        <StyledReactPlayer
          ref={ref}
          url={url}
          playing={isPlaying}
          loop={isLooping}
          width="100%"
          height="100%"
          volume={1}
          muted={isMuted}
          playsinline
          config={{
            vimeo: {
              playerOptions: {
                controls: showingControls,
                dnt: true, // 'Do not track' prevents the player from tracking session data, including cookies
              },
            },
          }}
          onEnded={onEnded}
        />
      </StyledPlayerWrapper>
    </StyledVideoWrapper>
  )
);

VideoPlayer.defaultProps = {
  aspectRatio: '16/9',
  onEnded: () => {},
};

const StyledVideoWrapper = styled.div`
  position: relative;
`;

const StyledPlayerWrapper = styled.div<{ aspectRatio?: string }>`
  position: relative;

  ${({ aspectRatio }) => {
    switch (aspectRatio) {
      case '1/1':
        return css`
          padding-top: 100%;
        `; // Same width and height. E.g. 1080/1080
      case '16/9':
      default:
        return css`
          padding-top: 56.25%;
        `; // E.g. 1920/1080
    }
  }}
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledSpinner = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);

  * {
    color: black;
  }
`;
