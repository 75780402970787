import styled from 'styled-components';

export const Button = styled.button`
  padding: 14px 24px;
  border: none;
  cursor: pointer;
  border-radius: 999px;
  font-family: ${({ theme }) => theme.fonts.sharpSansBold}, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  /* Remove highlight glow on click */
  -webkit-tap-highlight-color: transparent;

  /* Remove colored border on focus */
  &:focus {
    outline: none;
  }
`;
