import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { CSSTransitionGroup } from 'react-transition-group';
import GatsbyImage from 'gatsby-image';
import { Button } from '../../components/Button';
import { H1, P } from '../../components/page-elements';
import { Slice } from '../../components/Slice';

const Article = ({ data }) => {
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );

  const showButton = searchParams.get('showButton') !== 'false'; // https://example.com/?showButton=false

  const hasButton =
    data.prismicArticle.data.button_text &&
    data.prismicArticle.data.button_text.length > 0;

  const hasImage = data.prismicArticle.data.header_image &&
    data.prismicArticle.data.header_image.localFile &&
    data.prismicArticle.data.header_image.localFile.childImageSharp

  return (
    <StyledArticle>
      {hasImage && (
        <StyledHeader>
          <GatsbyImage
            fluid={
              data.prismicArticle.data.header_image.localFile.childImageSharp
              ?.fluid
            }
          />
        </StyledHeader>
      )}

      <StyledBody>
        <StyledBodyInner>
          <StyledIntro>
            <H1>{data.prismicArticle.data.headline}</H1>
            <P>{data.prismicArticle.data.lead_paragraph}</P>
          </StyledIntro>

          {data.prismicArticle.data.body.map((section, index) => (
            <Slice key={index} type={section.slice_type} data={section} />
          ))}
        </StyledBodyInner>
      </StyledBody>

      {hasButton && showButton && (
        <StyledFooter>
          <CSSTransitionGroup
            transitionName={footerAnimationName}
            transitionAppear
            transitionAppearTimeout={9999999999} // Fix to make the appear-active class stay on the footer
            transitionEnter={false}
            transitionLeave={false}
          >
            <StyledFooterInner key="inner-footer">
              <a href={data.prismicArticle.data.button_deep_link}>
                <StyledButton>
                  {data.prismicArticle.data.button_text}
                </StyledButton>
              </a>
            </StyledFooterInner>
          </CSSTransitionGroup>
        </StyledFooter>
      )}
    </StyledArticle>
  );
};

const StyledArticle = styled.div`
  position: relative;
  max-width: 640px;
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  @media ${({ theme }) => theme.screen.md.up} {
    margin-top: 24px;
  }
`;

const StyledBody = styled.div`
  max-width: 560px;
  margin: 0 auto;
`;

const StyledBodyInner = styled.div`
  padding: 32px 16px 64px 16px;
`;

const StyledIntro = styled.div`
  margin-bottom: 48px;

  p {
    margin: 0;
    font-size: 19px;
    line-height: 1.4;
  }
`;

const StyledFooter = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const footerAnimationName = 'animate-footer-in';
const StyledFooterInner = styled.div`
  margin: 0 auto;
  padding: 0 16px 40px 16px;
  max-width: 560px;
  transform: translate3d(0, 100%, 0);
  transition: transform 400ms cubic-bezier(0.65, 0.05, 0.36, 1) 500ms;

  &.${footerAnimationName}-appear {
  }

  &.${footerAnimationName}-appear-active {
    transform: translate3d(0, 0, 0);
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default Article;

export const pageQuery = graphql`
  query ArticleQuery($id: String!) {
    prismicArticle(id: { eq: $id }) {
      id
      data {
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        headline
        lead_paragraph
        button_text
        button_deep_link
        body {
          ... on PrismicArticleBodySection {
            id
            slice_type
            primary {
              text {
                raw
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleBodyVideoSection {
            id
            slice_type
            primary {
              text {
                raw
              }
              video_vimeo_url
              play_option
              video_thumbnail_image {
                url
              }
              video_thumbnail_image_alt
              video_play_button_image {
                url
              }
              video_play_button_image_alt
              video_aspect_ratio
            }
          }
          ... on PrismicArticleBodyDivider {
            id
            slice_type
          }
        }
      }
    }
  }
`;
